import getTenant from '../../getTenant';

const idm = `/org/user_management`;

export function getRegisterSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/register/`;
}

export function getLoginSlug(): string {
    return `${backendUrl}/auth/login?successSlug=news%2F`;
}

export function getAdfsUserManagementSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/adfs/`;
}

export function getApplicationsSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/applications/`;
}

export function getNewsletterSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/newsletter/`;
}

export function getUserProfilePageUrl(): string {
    return `${getTenant().toLowerCase()}/org/user_management/user_profile`;
}

export function getWelcomePageSlug(): string {
    return `${getTenant().toLowerCase()}/`;
}

export function getAdfsChangePasswordUrl(): string {
    return `${backendUrl}/adfs/change_password`;
}

export function getAdfsChangeMfaUrl(): string {
    return `${backendUrl}/adfs/change_mfa`;
}

export function getLogoutUrl(): string {
    return `${backendUrl}/single-logout`;
}

import React, { ReactElement } from 'react';
import { Heading } from '@dm/design-system';
import RichText from '../../content/richtextRenderer/RichText';
import { componentMap, linkMap } from '../../content/richtextRenderer/config';
import BlokRenderer from '../../content/BlokRenderer';
import { RichTextNode } from '../../content/richtextRenderer/render';
import { Blok } from '../../content/StoryblokFieldTypes';

type Props = {
    Title: string;
    Description: RichTextNode;
    contentBloks?: Blok[];
};

export default function UserProfilePage({ Description, Title, contentBloks }: Readonly<Props>): ReactElement {
    return (
        <div>
            <Heading kind="primary" style={{ paddingBottom: '11px', wordWrap: 'break-word' }}>
                {Title}
            </Heading>
            <RichText componentMap={componentMap} linkMap={linkMap} richText={Description} />
            {contentBloks && <BlokRenderer bloks={contentBloks} />}
        </div>
    );
}

import { Button, FelaStyle, ThemeProps } from '@dm/design-system';
import React, { ReactElement } from 'react';

const loginButtonStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    width: 150,
    margin: theme.dimension.spacing.xxxs.rem,
});

export interface LoginProps {
    label: string;
}

export default function Login({ label }: Readonly<LoginProps>): ReactElement {
    return (
        // FIX THAT Success Url to be news page FIX WHEN news page is implemented
        // FIX THAT Failure Url to be new failure page FIX WHEN failure page is implemented
        // FIX THAT dynamically set tenant FIX WHEN we have multiple tenants
        <form action={`${backendUrl}/auth/login`} method="GET">
            <input type="hidden" name="successSlug" value="news/" />
            <Button style={loginButtonStyle} type="submit" title={label}>
                {label}
            </Button>
        </form>
    );
}

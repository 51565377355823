

// generated code

export interface AppInfo {
   packageVersion: string,
   gitShortHash: string,
   gitHash: string,
   commitDateShort: string,
   commitDateTimeShort: string
   fullVersion: string
}

const appInfo : AppInfo = {
  packageVersion : '0.1.0',
  gitShortHash: '6e435d2',
  gitHash: '6e435d2c8efe1f9dda8423564502e560ee7c540a',
  commitDateShort: '2024-08-14',
  commitDateTimeShort: '2024-08-14T15:06:46',
  fullVersion: '0.1.0-2024-08-14.6e435d2'
};

export default appInfo;


import { Box, Link, Spacer, Text } from '@dm/design-system';
import React, { ReactElement } from 'react';
import Heading from '../../richtextRenderer/components/Heading';
import getTenant from '../../getTenant';

export interface ErrorPageProps {
    title: string;
    description: string;
    additionalButtons?: ReactElement;
}

export default function ErrorPage({ title, description, additionalButtons }: Readonly<ErrorPageProps>): ReactElement {
    return (
        <div style={{ display: 'block' }}>
            <Box>
                <Spacer size={50} />
                <Heading>{title}</Heading>
                <Spacer size={20} />
                <Text>{description}</Text>
                <Spacer size={20} />
                <Link kind="secondary" as={Link} href={`/${getTenant().toLocaleLowerCase()}/`}>
                    Startseite
                </Link>
                {additionalButtons}
                <Spacer size={50} />
            </Box>
        </div>
    );
}

import React from 'react';
import { MyUserDataSection } from '../types';
import MyUserDataSectionEntry from './MyUserDataSectionEntry';
import MyUserDataSectionHeader from './MyUserDataSectionHeader';

export default function MyUserDataProfileSection({ heading, entries }: Readonly<MyUserDataSection>) {
    return (
        <>
            <MyUserDataSectionHeader heading={heading} />
            {entries.map((entry) => (
                <MyUserDataSectionEntry label={entry.label} value={entry.value} key={entry.value} />
            ))}
        </>
    );
}

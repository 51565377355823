import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

export default function NotAuthenticatedMessage(): ReactElement {
    const { t } = useTranslation();

    let title = t(`errorPage.notAuthenticated.title`);
    let description = t(`errorPage.notAuthenticated.description`);

    if (title === 'errorPage.notAuthenticated.title') {
        title = 'Kein Zugriff';
    }

    if (description === 'errorPage.notAuthenticated.description') {
        description = 'Sie sind nicht mehr angemeldet. Bitte melden Sie sich neu an.';
    }

    return <ErrorPage title={title} description={description} />;
}

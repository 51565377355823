import { ComponentType, createElement, ReactElement } from 'react';
import componentMap from './componentMap';
import ErrorMessage from './components/errorPage/ErrorMessage';
import Transitioning from './components/empty/Transitioning';
import { useStoryContext } from './StoryProvider';

export type ComponentMap = Record<string, ComponentType<never>>;

export default function StoryRenderer(): ReactElement {
    const { pending, error, story } = useStoryContext();

    if (pending) {
        return createElement(Transitioning);
    }
    if (error) {
        return createElement(ErrorMessage);
    }

    return createElement(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        componentMap[story.content.component] ?? ErrorMessage,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        story.content
    );
}

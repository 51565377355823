/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Personal
 */
export interface Personal {
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof Personal
     */
    entryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Personal
     */
    exitDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    masterId?: string;
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    supplierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof Personal
     */
    type?: string;
}

export function PersonalFromJSON(json: any): Personal {
    return PersonalFromJSONTyped(json, false);
}

export function PersonalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Personal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'entryDate': !exists(json, 'entryDate') ? undefined : (new Date(json['entryDate'])),
        'exitDate': !exists(json, 'exitDate') ? undefined : (new Date(json['exitDate'])),
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'masterId': !exists(json, 'masterId') ? undefined : json['masterId'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'supplierNumber': !exists(json, 'supplierNumber') ? undefined : json['supplierNumber'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function PersonalToJSON(value?: Personal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'entryDate': value.entryDate === undefined ? undefined : (value.entryDate.toISOString()),
        'exitDate': value.exitDate === undefined ? undefined : (value.exitDate.toISOString()),
        'firstname': value.firstname,
        'lastname': value.lastname,
        'masterId': value.masterId,
        'phone': value.phone,
        'supplierNumber': value.supplierNumber,
        'tenantId': value.tenantId,
        'type': value.type,
    };
}



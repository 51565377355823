import React, { ReactElement } from 'react';
import { Box, Text, useFela } from '@dm/design-system';
import { Asset } from '../StoryblokFieldTypes';
import getTenant, { supportedLanguagesForTenants } from '../getTenant';

interface VideoPlayerProps {
    video: Asset;
    captions: Asset;
}

export default function Video({ video, captions }: Readonly<VideoPlayerProps>): ReactElement<VideoPlayerProps> {
    const { css } = useFela();

    const fullWidthVideoStyle = {
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        height: 0,
        width: '100%',
        '> video': {
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
    };

    return (
        <Box className={css(fullWidthVideoStyle)}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video src={video.filename} controls title={video.name} controlsList="nodownload">
                {captions.filename && (
                    <track
                        kind="captions"
                        src={captions.filename}
                        srcLang={supportedLanguagesForTenants[getTenant()]}
                    />
                )}
                <Text>Your browser does not support HTML video.</Text>
            </video>
        </Box>
    );
}

import { useEffect, useRef, useState } from 'react';
import { authenticationApi } from '../apiConfig';
import { SessionContext } from './SessionProvider';

export interface UseAuthProps {
    sessionContext?: SessionContext;
}

export default function useAuth({ sessionContext }: UseAuthProps): SessionContext {
    const [session, setSession] = useState<SessionContext>({
        authentication: 'pending',
        userInfo: null,
    });

    const hasFetchedUserInfo = useRef(false);

    useEffect(() => {
        if (sessionContext) {
            setSession(sessionContext);
            return;
        }
        if (hasFetchedUserInfo.current) return;

        authenticationApi
            .whoami()
            .then((optionalUserInfo) => {
                setSession({
                    ...session,
                    userInfo: optionalUserInfo.userInfo,
                    authentication: optionalUserInfo.userInfo !== null ? 'success' : 'unauthorized',
                });
            })
            .catch(
                () => setSession({ ...session, authentication: 'failed', userInfo: null })
                // FIX THAT should show error notification FIX WHEN we have global notification container
            );
        hasFetchedUserInfo.current = true;
    }, [session, hasFetchedUserInfo, sessionContext]);
    return session;
}

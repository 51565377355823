/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Personal,
    PersonalFromJSON,
    PersonalFromJSONTyped,
    PersonalToJSON,
    Supplier,
    SupplierFromJSON,
    SupplierFromJSONTyped,
    SupplierToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {Personal}
     * @memberof UserDetails
     */
    personal?: Personal;
    /**
     * 
     * @type {Supplier}
     * @memberof UserDetails
     */
    supplier?: Supplier;
}

export function UserDetailsFromJSON(json: any): UserDetails {
    return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personal': !exists(json, 'personal') ? undefined : PersonalFromJSON(json['personal']),
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
    };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personal': PersonalToJSON(value.personal),
        'supplier': SupplierToJSON(value.supplier),
    };
}



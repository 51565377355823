import React from 'react';
import { Shadow } from '@dm/design-system';

type Props = {
    children: React.ReactNode;
};

const myUserDataPageContainerStyle = {
    wordWrap: 'break-word',
    padding: '15px',
};

export default function MyUserDataProfilePageContainer({ children }: Readonly<Props>) {
    return <Shadow style={myUserDataPageContainerStyle}>{children}</Shadow>;
}

import { Box, Text } from '@dm/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TrackedLink from '../../../app/components/tracking/TrackedLink';
import { getUserProfilePageUrl } from '../../../content/components/idm/routes';

export default function UserProfileNavigation() {
    const { t } = useTranslation();
    const linkLabel = t('myProfile.overview.link');

    return (
        <Box dmId="userProfileNavigation-id" style={{ flexDirection: 'row', marginBottom: '20px', fontSize: '14px' }}>
            <TrackedLink to={`/${getUserProfilePageUrl()}`} title={linkLabel} trackedType="userProfile">
                <Text>{linkLabel}</Text>
            </TrackedLink>
        </Box>
    );
}

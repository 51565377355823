import { Box } from '@dm/design-system';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getApplicationsSlug,
    getLoginSlug,
    getNewsletterSlug,
    getRegisterSlug,
    getUserProfilePageUrl,
} from '../../content/components/idm/routes';
import { useSession } from '../../session/SessionProvider';
import FlyoutWidget from '../../app/components/Flyout/FlyoutWidget';
import { configApi } from '../../apiConfig';
import UserProfileMenuEntry from './components/UserProfileMenuEntry';
import UserProfileMenuHeader from './components/UserProfileMenuHeader';
import UserProfileMenuFooter from './components/UserProfileMenuFooter';
import AnonymousUserProfileMenuEntry from './components/AnonymousUserProfileMenuEntry';
import UserManagementWidgetIcon from './components/UserManagementWidgetIcon';

const applicationSlug = `${getApplicationsSlug()}`;
const newsletterSlug = `${getNewsletterSlug()}`;
const profilePageSlug = `${getUserProfilePageUrl()}`;

export const orderedLoggedInMenuItems = {
    [applicationSlug]: {
        id: 'applications',
        label: 'myaccount.applications.title',
        targetSlug: applicationSlug,
    },
    [newsletterSlug]: {
        id: 'newsletter',
        label: 'myaccount.newsletter.title',
        targetSlug: newsletterSlug,
    },
};

const anonymousUserMenuEntries = [
    <AnonymousUserProfileMenuEntry
        key="login-button"
        id="login-button"
        kind="primary"
        targetSlug={`${getLoginSlug()}`}
        label="myaccount.login.title"
    />,
    <AnonymousUserProfileMenuEntry
        key="registration-button"
        id="registration-button"
        kind="secondary"
        targetSlug={`/${getRegisterSlug()}`}
        label="myaccount.register.title"
    />,
];

export default function UserProfileMenu(): ReactElement {
    const { userInfo } = useSession();
    const { t } = useTranslation();

    const [userMenuEntries, setUserMenuEntries] = useState<JSX.Element[]>(anonymousUserMenuEntries);

    const createLoggedInUserMenuEntries = useCallback(
        (availableMenuStoriesAsFullSlugs: string[]) => {
            if (!userInfo) {
                return anonymousUserMenuEntries;
            }
            const loggedInUserMenuEntries: JSX.Element[] = [
                <UserProfileMenuHeader
                    id="user-profile-menu-header"
                    key="user-profile-menu-header"
                    label={userInfo.fullName}
                />,
                <UserProfileMenuEntry
                    id="profilePage"
                    key="myaccount.myProfile.title"
                    targetSlug={profilePageSlug}
                    label={t('myaccount.myProfile.title')}
                />,
            ];

            Object.values(orderedLoggedInMenuItems)
                .filter((menuEntry) => availableMenuStoriesAsFullSlugs.includes(menuEntry.targetSlug))
                .forEach((menuEntry) => {
                    loggedInUserMenuEntries.push(
                        <UserProfileMenuEntry
                            id={`user-profile-menu-entry-${menuEntry.id}`}
                            targetSlug={menuEntry.targetSlug}
                            label={t(menuEntry.label)}
                        />
                    );
                });
            loggedInUserMenuEntries.push(
                <UserProfileMenuFooter id="user-profile-menu-footer-logout" label={t('myaccount.logout.title')} />
            );

            return loggedInUserMenuEntries;
        },
        [userInfo, t]
    );

    useEffect(() => {
        if (userInfo) {
            const listOfFullSlugs = Object.keys(orderedLoggedInMenuItems);

            configApi.getAvailableUserMenuStories({ listOfFullSlugs }).then((response) => {
                setUserMenuEntries(createLoggedInUserMenuEntries(response.userMenuFullSlugs ?? []));
            });
        }
    }, [userInfo, createLoggedInUserMenuEntries]);

    return (
        <Box>
            <FlyoutWidget
                icon={<UserManagementWidgetIcon isLoggedIn={!!userInfo} />}
                label="user profile menu"
                elements={userMenuEntries}
                dmId="user-profile-menu"
            />
        </Box>
    );
}

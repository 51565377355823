import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

export default function AuthenticatedMessage(): ReactElement {
    const { t } = useTranslation();

    let title = t(`errorPage.authenticated.title`);
    let description = t(`errorPage.authenticated.description`);

    if (title === 'errorPage.authenticated.title') {
        title = 'Entschuldigung, die gewünschte Seite ist nicht aktiv.';
    }

    if (description === 'errorPage.authenticated.description') {
        description =
            'Inhalt konnte nicht geladen werden. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.';
    }

    return <ErrorPage title={title} description={description} />;
}

/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Colleague,
    ColleagueFromJSON,
    ColleagueToJSON,
    SupplierContact,
    SupplierContactFromJSON,
    SupplierContactToJSON,
} from '../models';

/**
 * UserprofilApi - interface
 * 
 * @export
 * @interface UserprofilApiInterface
 */
export interface UserprofilApiInterface {
    /**
     * 
     * @summary returns the colleagues the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilApiInterface
     */
    myColleaguesRaw(): Promise<runtime.ApiResponse<Array<Colleague>>>;

    /**
     * returns the colleagues the current user
     */
    myColleagues(): Promise<Array<Colleague>>;

    /**
     * 
     * @summary returns the supplier contacts the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserprofilApiInterface
     */
    supplierContactsRaw(): Promise<runtime.ApiResponse<Array<SupplierContact>>>;

    /**
     * returns the supplier contacts the current user
     */
    supplierContacts(): Promise<Array<SupplierContact>>;

}

/**
 * 
 */
export class UserprofilApi extends runtime.BaseAPI implements UserprofilApiInterface {

    /**
     * returns the colleagues the current user
     */
    async myColleaguesRaw(): Promise<runtime.ApiResponse<Array<Colleague>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profil/myColleagues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ColleagueFromJSON));
    }

    /**
     * returns the colleagues the current user
     */
    async myColleagues(): Promise<Array<Colleague>> {
        const response = await this.myColleaguesRaw();
        return await response.value();
    }

    /**
     * returns the supplier contacts the current user
     */
    async supplierContactsRaw(): Promise<runtime.ApiResponse<Array<SupplierContact>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/profil/supplierContacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SupplierContactFromJSON));
    }

    /**
     * returns the supplier contacts the current user
     */
    async supplierContacts(): Promise<Array<SupplierContact>> {
        const response = await this.supplierContactsRaw();
        return await response.value();
    }

}

import { FetchParams, RequestContext } from '../gen/ppapiclient';
import { securityApi } from './apiConfig';

let csrfToken: string | undefined;

export const clearCsrfToken = async () => {
    csrfToken = undefined;
};

const CSRF_TOKEN_HEADER = 'X-XSRF-TOKEN';

const updateCsrfToken = async () => {
    return securityApi
        .csrf()
        .then((csrfTokenInfo) => {
            csrfToken = csrfTokenInfo.token;
        })
        .catch((error) => {
            csrfToken = undefined;
        });
};

const requiresCsrf = (method: string | undefined): boolean => {
    return method === 'POST' || method === 'PUT' || method === 'DELETE';
};

const csrfMiddleware = async (context: RequestContext): Promise<FetchParams> => {
    const { method } = context.init;

    const csrfProtectionRequired = requiresCsrf(method);

    if (csrfProtectionRequired) {
        if (!csrfToken) {
            await updateCsrfToken();
        }
        if (csrfToken) {
            return {
                ...context,
                init: {
                    ...context.init,
                    headers: { ...context.init.headers, [CSRF_TOKEN_HEADER]: csrfToken },
                },
            };
        }
    }
    return context;
};

export default csrfMiddleware;

import React from 'react';

type Props = {
    title: string;
    url: string;
};

const iframeStyle = {
    border: 0,
    width: '100%',
    height: '100%',
};

export default function EmbeddedApplication({ title, url }: Readonly<Props>) {
    return <iframe title={title} src={url} style={iframeStyle} />;
}

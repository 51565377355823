import {
    ApplicationsApi,
    AuthenticationApi,
    ConfigApi,
    Configuration,
    ContentApi,
    IdmApi,
    NewsletterApi,
    NotificationsApi,
    SecurityApi,
    TermsofuseApi,
    UserprofilApi,
} from '../gen/ppapiclient';
import csrfMiddleware from './csrfMiddleware';
import maintenanceMiddleware from './maintenanceMiddleware';
import previewMiddleware from './previewMiddleware';

const configuration = new Configuration({
    basePath: backendUrl,
    credentials: 'include',
});

export const authenticationApi = new AuthenticationApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const idmApi = new IdmApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const newsletterApi = new NewsletterApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const applicationApi = new ApplicationsApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const termsApi = new TermsofuseApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const contentApi = new ContentApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const securityApi = new SecurityApi(configuration);

export const configApi = new ConfigApi(configuration);

export const notificationsApi = new NotificationsApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const userProfilApi = new UserprofilApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

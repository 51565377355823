/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CsrfToken,
    CsrfTokenFromJSON,
    CsrfTokenToJSON,
    ValidationErrorResponse,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models';

/**
 * SecurityApi - interface
 * 
 * @export
 * @interface SecurityApiInterface
 */
export interface SecurityApiInterface {
    /**
     * provides a csrf token to the SPA
     * @summary csrf token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApiInterface
     */
    csrfRaw(): Promise<runtime.ApiResponse<CsrfToken>>;

    /**
     * provides a csrf token to the SPA
     * csrf token
     */
    csrf(): Promise<CsrfToken>;

}

/**
 * 
 */
export class SecurityApi extends runtime.BaseAPI implements SecurityApiInterface {

    /**
     * provides a csrf token to the SPA
     * csrf token
     */
    async csrfRaw(): Promise<runtime.ApiResponse<CsrfToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/security/csrf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CsrfTokenFromJSON(jsonValue));
    }

    /**
     * provides a csrf token to the SPA
     * csrf token
     */
    async csrf(): Promise<CsrfToken> {
        const response = await this.csrfRaw();
        return await response.value();
    }

}

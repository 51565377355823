import React from 'react';
import { Box, Text } from '@dm/design-system';

type Props = {
    label: string;
    value: string | null;
};

export default function MyUserDataSectionEntry({ label, value }: Readonly<Props>) {
    return (
        <Box
            style={{
                justifyContent: 'flex-start',
                whiteSpace: 'pre',
                flexDirection: 'row',
            }}
        >
            <Text dmId="myUserDataSectionEntryLabel-id">{label}: </Text>
            <Text dmId="myUserDataSectionEntryValue-id">{value ?? '---'}</Text>
        </Box>
    );
}

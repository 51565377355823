import { translateToSlug } from '../navigation/slug';

const storyblokPreviewQueryParam = '_storyblok';
const spaceIdPreviewQueryParam = '_storyblok_tk[space_id]';
const timestampPreviewQueryParam = '_storyblok_tk[timestamp]';
const tokenPreviewQueryParam = '_storyblok_tk[token]';

export function preserveStoryblokPreviewParams(internalLink: string) {
    const storyblokPreviewParams = [
        storyblokPreviewQueryParam,
        spaceIdPreviewQueryParam,
        timestampPreviewQueryParam,
        tokenPreviewQueryParam,
    ];
    const currentStoryblokPreviewParams = new URLSearchParams('');
    new URLSearchParams(window.location.search).forEach((value, key) => {
        if (storyblokPreviewParams.includes(key)) {
            currentStoryblokPreviewParams.append(key, value);
        }
    });

    if (Array.from(currentStoryblokPreviewParams).length === 0) {
        return internalLink;
    }

    if (internalLink.includes('?')) {
        return `${internalLink}&${currentStoryblokPreviewParams.toString()}`;
    }

    return `${internalLink}?${currentStoryblokPreviewParams.toString()}`;
}

export function buildPreviewImageUrl(imagePath: string) {
    const imgUrl: URL = new URL(imagePath);

    const previewState = getPreviewState();
    const pathName = imgUrl.pathname.replace('/api/cms/asset', '');
    const previewUrl = addPreviewUrlParams(`${backendUrl}/api/cms/asset${pathName}`, previewState);
    previewUrl.searchParams.append('linkingStorySlug', previewState.fullSlug);

    return previewUrl;
}

export function addPreviewUrlParams(url: string, previewContext?: StoryblokPreviewState) {
    const previewUrl = new URL(url);
    const previewState = previewContext ?? getPreviewState();
    if (previewState.isPreviewMode && previewState.spaceId && previewState.timestamp && previewState.token) {
        previewUrl.searchParams.append('preview_spaceId', String(previewState.spaceId));
        previewUrl.searchParams.append('preview_timestamp', String(previewState.timestamp));
        previewUrl.searchParams.append('preview_token', String(previewState.token));

        return previewUrl;
    }
    return previewUrl;
}

export interface StoryblokPreviewState {
    isPreviewMode: boolean;
    fullSlug: string;
    spaceId?: number;
    timestamp?: number;
    token?: string;
}

export function getPreviewState() {
    const { search, pathname } = window.location;
    const searchParams = new URLSearchParams(search);

    const currentSlug = translateToSlug(pathname);

    let previewState: StoryblokPreviewState = {
        isPreviewMode: !!searchParams.get(storyblokPreviewQueryParam),
        fullSlug: currentSlug,
    };

    if (
        previewState.isPreviewMode &&
        searchParams.has(spaceIdPreviewQueryParam) &&
        searchParams.has(timestampPreviewQueryParam) &&
        searchParams.has(tokenPreviewQueryParam)
    ) {
        previewState = {
            ...previewState,
            spaceId: Number(searchParams.get(spaceIdPreviewQueryParam)),
            timestamp: Number(searchParams.get(timestampPreviewQueryParam)),
            token: String(searchParams.get(tokenPreviewQueryParam)),
        };
    }
    return previewState;
}

import React from 'react';
import { Shadow } from '@dm/design-system';

type Props = {
    children: React.ReactNode;
};

const cardStyle = {
    height: '100px',
    width: '400px',
    marginRight: '20px',
    wordWrap: 'break-word',
    padding: '15px',
    marginBottom: '20px',
};

export default function MyUserProfileCardContainer({ children }: Readonly<Props>) {
    return <Shadow style={cardStyle}>{children}</Shadow>;
}

import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import useAuth from './useAuth';
import { UserInfo } from '../gen/ppapiclient';

export interface SessionContext {
    authentication: 'pending' | 'success' | 'unauthorized' | 'failed';
    userInfo: UserInfo | null;
}

export const Context = createContext<SessionContext>({
    authentication: 'pending',
    userInfo: null,
});
Context.displayName = 'SessionContext';

export interface SessionProviderProps {
    children: ReactNode;
    testSessionContext?: SessionContext;
}

export function SessionProvider({
    children,
    testSessionContext = undefined,
}: Readonly<SessionProviderProps>): ReactElement {
    const session = useAuth({ sessionContext: testSessionContext });
    return <Context.Provider value={session}>{children}</Context.Provider>;
}

export const useSession = (): SessionContext => useContext(Context);

import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContentTreeItem, FetchParams, RequestContext, StoryResponse } from '../gen/ppapiclient';
import { translateToSlug } from '../navigation/slug';
import useStoryData from './useStoryData';
import getTenant from './getTenant';
import { contentApi } from '../apiConfig';

export interface StoryData {
    story: StoryResponse | null;
    error: boolean;
    pending: boolean;
    isMaintenanceActive: boolean;
}

type StoryProviderType = {
    storyData: StoryData;
    contentTree: ContentTree;
    isMaintenancePageActive(): boolean;
};

export interface ContentTree {
    navigation: ContentTreeItem[];
    footer: ContentTreeItem[];
}

const StoryContext = createContext<StoryProviderType>({
    storyData: {
        error: false,
        isMaintenanceActive: true,
        pending: false,
        story: null,
    },
    contentTree: {
        navigation: [],
        footer: [],
    } as ContentTree,
    isMaintenancePageActive: () => false,
});

export const checkMaintenanceState = async (
    context: RequestContext,
    storyContext?: StoryProviderType
): Promise<void | FetchParams> => {
    if (storyContext?.storyData.isMaintenanceActive && !context.url.includes('maintenance')) {
        return undefined; // Return undefined to indicate maintenance mode is active
    }

    return { url: context.url, init: context.init };
};

export function StoryProvider({ children }): ReactElement {
    const [contentTree, setContentTree] = useState<ContentTree>({
        navigation: [],
        footer: [],
    } as ContentTree);

    const [story, setStory] = useState<StoryData>({
        error: false,
        pending: true,
        isMaintenanceActive: false,
        story: null,
    } as StoryData);

    const { pathname } = useLocation();
    const slug = translateToSlug(pathname);
    const storyData = useStoryData(slug, getTenant());

    const fetchContentTree = () => {
        // Once the story data is loaded, continue with fetching the content tree
        contentApi
            .getTree({ tenant: getTenant() })
            .then((contentTreesResponse) => {
                const footerItems = contentTreesResponse.footer;
                const navigationItems = contentTreesResponse.navigation;

                setContentTree({
                    navigation: navigationItems,
                    footer: footerItems,
                });
            })
            .catch(() => {
                // do nothing
            });
    };

    const isMaintenancePageActive = useCallback(() => story.isMaintenanceActive, [story.isMaintenanceActive]);

    useEffect(() => setStory(storyData), [storyData]);

    useEffect(() => {
        if (!isMaintenancePageActive() && story.pending === false) {
            fetchContentTree();
        }
    }, [isMaintenancePageActive, story.pending]);

    const storyContext = useMemo(
        () => ({
            storyData: story,
            contentTree,
            isMaintenancePageActive,
        }),
        [story, contentTree, isMaintenancePageActive]
    );

    return <StoryContext.Provider value={storyContext}>{children}</StoryContext.Provider>;
}

export const useStoryProvider = (): StoryProviderType => useContext(StoryContext);

export const useContentTree = () => useStoryProvider().contentTree;
export const useMaintenanceState = () => useStoryProvider().isMaintenancePageActive();
export const useStoryContext = () => useStoryProvider().storyData;

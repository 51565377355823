import React, { useEffect, useState } from 'react';
import { Banner, Loading, Spacer, Table, TableColumn, Text, theme, View } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import UserProfileNavigation from './components/UserProfileNavigation';
import { userProfilApi } from '../../apiConfig';
import { SupplierContact } from '../../gen/ppapiclient';

export default function MySupplierContactsPage() {
    const { t } = useTranslation();

    const [supplierContacts, setSupplierContacts] = useState<SupplierContact[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        userProfilApi
            .supplierContacts()
            .then((response) => {
                setSupplierContacts(response);
                setIsLoading(false);
            })
            .catch(() => {
                setHasError(true);
                setIsLoading(false);
            });
    }, []);

    const columns: TableColumn<SupplierContact>[] = [
        {
            key: 'personName',
            heading: t('myProfile.supplierContacts.tableColumn.personName'),
            renderCell: ({ personName }) => <Text weight="bold">{personName}</Text>,
        },
        {
            key: 'department',
            heading: t('myProfile.supplierContacts.tableColumn.department'),
            renderCell: ({ department }) => <Text weight="bold">{department}</Text>,
        },
        {
            key: 'email',
            heading: t('myProfile.supplierContacts.tableColumn.email'),
            renderCell: ({ email }) => <Text weight="bold">{email}</Text>,
        },
        {
            key: 'phone',
            heading: t('myProfile.supplierContacts.tableColumn.phone'),
            renderCell: ({ phone }) => <Text weight="bold">{phone}</Text>,
        },
        {
            key: 'fax',
            heading: t('myProfile.supplierContacts.tableColumn.fax'),
            renderCell: ({ fax }) => <Text weight="bold">{fax}</Text>,
        },
    ];

    const fetchingSuccessful = !hasError && !isLoading && supplierContacts.length > 0;
    const fetchingSuccessfulButEmpty = !hasError && !isLoading && supplierContacts.length === 0;

    return (
        <View>
            <UserProfileNavigation />

            {isLoading && <Loading seasonal dmId="loading-my-supplier-contacts-page" />}
            {hasError && (
                <Banner dmId="supplier-contacts-page-error" kind="error">
                    {t('myProfile.supplierContacts.errorText')}
                </Banner>
            )}

            {fetchingSuccessfulButEmpty && (
                <Banner dmId="supplier-contacts-page-no-data" kind="neutral">
                    {t('myProfile.supplierContacts.noDataText')}
                </Banner>
            )}
            {fetchingSuccessful && <Table rows={supplierContacts} columns={columns} stickyHeader />}
            <Spacer size={theme.dimension.spacing.s.rem} style={{ marginBottom: 20 }} />
        </View>
    );
}

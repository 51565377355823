import { Separator, Spacer, theme, View } from '@dm/design-system';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../session/SessionProvider';
import { MyUserDataSection, PersonalUserDetails, SupplierUserDetails } from './types';
import UserProfileNavigation from './components/UserProfileNavigation';
import MyUserDataProfileSection from './components/MyUserDataProfileSection';
import MyUserDataProfilePageContainer from './components/MyUserDataProfilePageContainer';

export default function MyUserDataPage() {
    const { userInfo } = useSession();
    const { t } = useTranslation();

    const personalDetails = userInfo?.details?.personal;
    const supplierDetails = userInfo?.details?.supplier;

    const personalUserDetails: PersonalUserDetails = useMemo(
        () => ({
            email: personalDetails?.email ?? null,
            entryDate: formatDate(personalDetails?.entryDate ?? null),
            exitDate: formatDate(personalDetails?.exitDate ?? null),
            firstName: personalDetails?.firstname ?? null,
            lastName: personalDetails?.lastname ?? null,
            phone: personalDetails?.phone ?? null,
            supplierId: personalDetails?.supplierNumber ?? null,
            userType: personalDetails?.type ?? null,
        }),
        [personalDetails]
    );

    const supplierUserDetails: SupplierUserDetails = useMemo(
        () => ({
            name: supplierDetails?.name ?? null,
            category: supplierDetails?.categories?.toString() ?? null,
        }),
        [supplierDetails]
    );

    const personalDataSection: MyUserDataSection = {
        heading: t('myProfile.myData.personalData'),
        entries: [
            {
                label: t('myProfile.myData.personalData.name'),
                value:
                    personalUserDetails.firstName && personalUserDetails.lastName
                        ? `${personalUserDetails.firstName} ${personalUserDetails.lastName}`
                        : null,
            },
            {
                label: t('myProfile.myData.personalData.phone'),
                value: personalUserDetails.phone,
            },
            {
                label: t('myProfile.myData.personalData.eMail'),
                value: personalUserDetails.email,
            },
            { label: t('myProfile.myData.personalData.EntryDate'), value: personalUserDetails.entryDate },
            { label: t('myProfile.myData.personalData.ExitDate'), value: personalUserDetails.exitDate },
            {
                label: t('myProfile.myData.personalData.SupplierNumber'),
                value: personalUserDetails.supplierId,
            },
            { label: t('myProfile.myData.personalData.Type'), value: personalUserDetails.userType },
        ],
    };

    const personalSupplierSection: MyUserDataSection = {
        heading: t('myProfile.myData.personalSupplierData'),
        entries: [
            {
                label: t('myProfile.myData.personalData.name'),
                value: supplierUserDetails.name,
            },
            {
                label: t('myProfile.myData.personalData.category'),
                value: supplierUserDetails.category,
            },
        ],
    };

    function formatDate(date: Date | null): string | null {
        if (!date) {
            return null;
        }

        return date.toLocaleDateString();
    }

    return (
        <View>
            <UserProfileNavigation />
            <MyUserDataProfilePageContainer>
                <MyUserDataProfileSection heading={personalDataSection.heading} entries={personalDataSection.entries} />
                <Separator />
                <MyUserDataProfileSection
                    heading={personalSupplierSection.heading}
                    entries={personalSupplierSection.entries}
                />
            </MyUserDataProfilePageContainer>
            <Spacer size={theme.dimension.spacing.s.rem} style={{ marginBottom: 20 }} />
        </View>
    );
}

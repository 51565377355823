import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { termsApi } from '../../../apiConfig';
import { TermsResponse } from '../../../gen/ppapiclient';
import { useSession } from '../../../session/SessionProvider';
import getTenant from '../../getTenant';

const initialTerms: TermsResponse[] = [];
type TermsContextType = {
    error: boolean;
    terms: TermsResponse[];
    updateTerms: () => void;
};
export const TermsContext = createContext<TermsContextType>({
    error: false,
    terms: initialTerms,
    updateTerms: () => {
        // empty initial method
    },
});

export function TermsProvider({ children }): ReactElement {
    const [termsState, setTermsState] = useState<TermsResponse[]>(initialTerms);
    const [errorState, setErrorState] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const tenant = getTenant();
    const { authentication } = useSession();

    const redirectTarget = `/${tenant.toLocaleLowerCase()}/org/footer/nutzungsbedingungen`;

    const updateTerms = useCallback(() => {
        termsApi
            .getTerms({ tenant, showAllTerms: false })
            .then(setTermsState)
            .catch(() => setErrorState(true));
    }, [tenant]);

    useEffect(() => {
        if (authentication === 'unauthorized' || authentication === 'success') {
            updateTerms();
        }
    }, [authentication, updateTerms, tenant]);

    let mustConfirm = false;
    termsState.forEach((term) => {
        if (!term.confirmed) {
            mustConfirm = true;
        }
    });

    if (
        mustConfirm &&
        authentication === 'success' &&
        location.pathname !== redirectTarget &&
        location.pathname !== '/de/login-error'
    ) {
        navigate(redirectTarget);
    }

    const termsContext = useMemo(
        () => ({
            error: errorState,
            terms: termsState,
            updateTerms,
        }),
        [termsState, updateTerms, errorState]
    );
    return <TermsContext.Provider value={termsContext}>{children}</TermsContext.Provider>;
}

export const useTerms = (): TermsContextType => useContext(TermsContext);

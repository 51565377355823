import { Box, FelaStyle, Spacer, ThemeProps, useFela, View } from '@dm/design-system';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import getTenant from '../content/getTenant';
import StoryRenderer from '../content/StoryRenderer';
import Footer from './components/Footer';
import Header from './components/Header';
import Sidebar from './components/navigation/Sidebar';
import { useSession } from '../session/SessionProvider';
import RegistrationPage from '../features/Registration/RegistrationPage';
import Applications from '../features/Applications/Applications';
import TermsPage from '../content/components/termsPage/TermsPage';
import SearchPage from '../content/components/searchPage/SearchPage';
import NewsletterPage from '../content/components/newsletter/NewsletterPage';
import MaintenanceContainer from '../content/components/maintenance/MaintenanceContainer';
import { useMaintenanceState } from '../content/StoryProvider';
import NewsletterOptOutConfirmation from '../features/Newsletter/NewsletterOptOutConfirmation';
import NewsletterOptInConfirmation from '../features/Newsletter/NewsletterOptInConfirmation';

const startPage = `/${getTenant().toLowerCase()}/`;
const newsPage = `${startPage}news/`;

export const appStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    fontFamily: theme.typography.fontFamily.primary.main,
    backgroundColor: theme.palette.background.hex,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

export const rowStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: theme.dimension.spacing.xxs.rem,
    paddingRight: theme.dimension.spacing.xxs.rem,
    display: 'block',
    [theme.breakpoints.horizontal.xs.mediaQuery]: {
        display: 'flex',
    },
});

const spacerStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    size: '0px',
    [theme.breakpoints.horizontal.l.mediaQuery]: {
        size: theme.dimension.spacing.s.rem,
    },
});

export const mainStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    flex: '1',
    paddingTop: '26px',
    [theme.breakpoints.horizontal.m.mediaQuery]: {
        paddingLeft: '100px',
        paddingRight: '20px',
    },
    [theme.breakpoints.horizontal.l.mediaQuery]: {
        paddingRight: '100px',
    },
});

export default function App(): JSX.Element {
    const { css } = useFela<ThemeProps>();
    const { authentication } = useSession();
    const isMaintenanceActive = useMaintenanceState();

    function createRedirectForEmptySlug() {
        const redirectTarget = authentication === 'success' ? newsPage : startPage;
        return <Route path="/" element={<Navigate replace to={redirectTarget} />} />;
    }

    return isMaintenanceActive ? (
        <MaintenanceContainer />
    ) : (
        <div className={css(appStyle)}>
            <header>
                <Header />
            </header>
            <Box
                style={{
                    flex: '1',
                    flexBasis: 'auto', // IE11 needs flexBasis
                    minHeight: '50vh',
                }}
            >
                <View noPadding>
                    <Box style={rowStyle}>
                        <Sidebar />
                        <Spacer size={css(spacerStyle)} />
                        <main className={css(mainStyle)}>
                            <Routes>
                                {createRedirectForEmptySlug()}
                                <Route path="/:tenant/org/user_management/register/" element={<RegistrationPage />} />
                                <Route path="/:tenant/org/user_management/applications/" element={<Applications />} />
                                <Route path="/:tenant/org/user_management/newsletter/" element={<NewsletterPage />} />
                                <Route path="/:tenant/org/footer/nutzungsbedingungen" element={<TermsPage />} />
                                <Route
                                    path="/:tenant/org/user_management/newsletter/opt_out_confirmation"
                                    element={<NewsletterOptOutConfirmation />}
                                />
                                <Route
                                    path="/:tenant/org/user_management/newsletter/opt_in_confirmation"
                                    element={<NewsletterOptInConfirmation />}
                                />
                                <Route path="/:tenant/search" element={<SearchPage />} />
                                <Route path="*" element={<StoryRenderer />} />
                            </Routes>
                        </main>
                    </Box>
                </View>
            </Box>
            <footer>
                <Footer />
            </footer>
        </div>
    );
}
